<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 230px)" :data="tableData" style="width: 100%;">
      <el-table-column  align="center"
                        type="index"
                         width="55" label="#" >
      </el-table-column>
      <el-table-column width="116" prop="nickname" label="昵称" >
      </el-table-column>
      <el-table-column width="116" prop="real_name" label="真实姓名" >
      </el-table-column>
      <el-table-column min-width="120" prop="ipaddr" label="IP" >
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="content" label="描述" >
      </el-table-column>
      <el-table-column width="80" prop="status" label="状态" >
        <template #default="scope">
           <el-tag size="mini" v-if="scope.row.logged==1">登录成功</el-tag>
           <el-tag size="mini" type="info" v-if="scope.row.logged==0">登录失败</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40,50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
    <el-drawer
        title="状态修改"
        v-model="drawer"
        size="30%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="110px"
          class="demo-ruleForm">
        <el-form-item label="状态" prop="mode">
          <el-select style="width: 100%" @change="modefun" v-model="ruleForm.check_status" >
            <el-option
                v-for="item in modeArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
<!--          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>-->
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import { getArea } from "@/api/common";
import * as axios from "@/api/skuApi"//本页面接口
export default {
  name: 'Grade',
  data(){
    return{
      skuValName:'',
      login:false,
      drawer:false,
      tableData:[],
      areaArr:[],//城市
      page:1,
      limit:10,
      total:0,
      keywords:'',
      ruleForm:{
        check_status:0,
      },
      modeArr:[
        {
          id:0,
          name:'无',
        },
        {
          id:1,
          name:'通过',
        },
        {
          id:2,
          name:'不通过',
        },
      ],
      rules:{
        name: [
          { required: true, message: '请填写名称', trigger: 'blue' },
        ],
        start_date: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        end_date: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
    }
  },
  created() {
    this.onTable()
    this.onArea()
    // this.keyupSubmit()
  },
  methods:{
    // keyupSubmit(){
    //   document.onkeydown=e=>{
    //     let _key=window.event.keyCode;
    //     if(_key===13){
    //       this.search()
    //     }
    //   }
    // },
    modefun(){
      this.ruleForm.times=0
      this.ruleForm.start_date=''
      this.ruleForm.end_date=''
    },
    onArea(){
        getArea().then((res)=>{
          this.areaArr=res.data
        }).catch((err)=>{

        })
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
      }
        axios.getSkuData(form,'/api/admin/member/loginlog').then((res)=>{
          console.log(res)
          this.tableData=res.data.data
          console.log('this.tableData',this.tableData)
          this.total=res.data.total
        }).catch((err)=>{

        })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },


    add(){
      this.ruleForm={
        check_status:0,
      }
      this.drawer=true
    },
    upData(row){
      console.log(row)
      this.ruleForm.id=row.id
      this.ruleForm.check_status=row.check_status
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postSkuData(this.ruleForm,'/api/admin/member/withdraw/add').then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.onTable()
                  let that=this
                  setTimeout(function () {
                    that.drawer=false
                    that.login=false
                  },1000)
                }else {
                  this.login=false
                  this.$message.error(res.msg)
                }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.postSkuData(this.ruleForm,'/api/admin/worker_certificate/edit').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }
        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postSkuData({id:row.id},'/api/admin/member/withdraw/del').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },
  }
}
</script>
<style lang="less" >
</style>